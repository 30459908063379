import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { useCallback, useEffect } from 'react'
import { FIREBASE_PUSH_KEY } from '~/core/constants/env'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import MutationFirebaseDeviceRegistrationCreate from '~/lib/graphql/mutation-firebase-device-registration'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import useBoundStore from '~/lib/store'
import { firebaseApp } from '../firebase-messaging-sw'
import { IUserInformation } from '~/core/@types/global'

export const FIREBASE_REQUEST_EXPLAIN = {
  // Change plan:
  PlanSubscriptionChanged: {
    title: 'PlanSubscriptionChanged',
    content: 'true'
  },
  // Change user permission:
  userPermissionsListChanged: {
    title: 'userPermissionsListChanged',
    content: 'true'
  },
  // Change referral:
  tenantReferralSettingChanged: {
    title: 'tenantReferralSettingChanged',
    content: 'true'
  },
  // Change requisition:
  tenantRequisitionSettingChanged: {
    title: 'tenantRequisitionSettingChanged',
    content: 'true'
  },
  // Change on/off career site setting
  tenantCareerSiteSettingChanged: {
    title: 'tenantCareerSiteSettingChanged',
    content: 'true'
  }
}

export interface IFirebaseToken {
  id: string
  deviceToken: string
  deviceKind?: string
  user?: IUserInformation
}

const useFcmToken = () => {
  const { user, deviceRegistrations, setDeviceRegistrations, setToast } =
    useBoundStore()
  const { trigger, isLoading } = useSubmitCommon(
    MutationFirebaseDeviceRegistrationCreate
  )

  useEffect(() => {
    const retrieveToken = async () => {
      try {
        if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
          const messaging = getMessaging(firebaseApp)

          const getFCMToken = async () => {
            const currentToken = await getToken(messaging, {
              vapidKey: FIREBASE_PUSH_KEY
            })
            if (currentToken) {
              if (
                deviceRegistrations?.filter(
                  (device) =>
                    String(device.deviceToken) === String(currentToken)
                )?.length === 0
              ) {
                await onUpdateFCMCallback(currentToken)
              }
            } else {
              console.log(
                'No registration token available. Request permission to generate one.'
              )
            }
          }

          // Retrieve the notification permission status
          const permission = await Notification.requestPermission()

          // Check if permission is granted before retrieving the token
          if (permission === 'granted') {
            await getFCMToken()
          } else if (Notification.permission !== 'denied') {
            // We need to ask the user for permission
            Notification.requestPermission().then(async (permission) => {
              // If the user accepts, let's create a notification
              if (permission === 'granted') {
                await getFCMToken()
              }
            })
          }
        }
      } catch (error) {
        console.log('An error occurred while retrieving token:', error)
      }
    }

    if (user?.currentTenant?.id && deviceRegistrations !== undefined) {
      retrieveToken()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, deviceRegistrations])

  const onUpdateFCMCallback = useCallback(
    async (currentToken: string) => {
      if (isLoading) {
        return
      }

      trigger({
        deviceToken: currentToken
      }).then((result) => {
        if (result.error) {
          return false
        }

        const { firebaseDeviceRegistrationCreateOrUpdate } = result.data
        if (firebaseDeviceRegistrationCreateOrUpdate.deviceRegistration) {
          setDeviceRegistrations([
            ...(deviceRegistrations || []),
            firebaseDeviceRegistrationCreateOrUpdate.deviceRegistration
          ])
        }
        return true
      })
    },
    [isLoading, trigger, setToast, setDeviceRegistrations, deviceRegistrations]
  )

  const subscribeFCM = (callback?: Function) => {
    const messaging = getMessaging(firebaseApp)

    onMessage(messaging, (payload) => {
      if (payload) {
        callback && callback(payload)
      }
    })
  }

  return { subscribeFCM }
}

export default useFcmToken

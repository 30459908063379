import { ReactElement, useEffect, useMemo, useState } from 'react'
import Cookies from 'js-cookie'
import {
  getUserAuthentication,
  handleAuthenticationFlow,
  redirectTo
} from './auth-utilities'
import { useRouter } from 'next/router'
import configuration from '~/configuration'
import { SESSION_COOKIE_CURRENT_TENANT } from '~/core/constants/cookies'
import useSubscriptionPlan from '~/components/Subscription/useSubscriptionPlan'
import { SETTINGS_CAREERS_URL } from '~/core/constants/url'

const withClientAuthenticationMiddleware = <T extends { pageProps: object }>(
  Component: (props: any) => ReactElement,
  matcher: string[] = []
) => {
  const WithClientAuthenticationMiddleware = <F extends T>(props: F) => {
    const router = useRouter()
    const [suspend, setSuspend] = useState(true)
    const isMatch = useMemo(
      () =>
        matcher.find(
          (pattern) =>
            router.asPath.match(pattern) ||
            [SETTINGS_CAREERS_URL].includes(router.asPath)
        ),
      [router]
    )
    const { data } = useSubscriptionPlan()
    useEffect(() => {
      if (router.isReady) {
        if (isMatch) {
          const cookies = Cookies.get()
          const planInfo = data && {
            isExpired: data?.isExpired,
            defaultPlan: data?.defaultPlan
          }
          const flowResult = handleAuthenticationFlow({
            user: getUserAuthentication(cookies),
            resolvedUrl: router.asPath,
            query: router.query,
            redirectPath: configuration.path.login,
            currentTenant: cookies[SESSION_COOKIE_CURRENT_TENANT],
            extendedReturn: {},
            planInfo
          })
          const redirectResponse = flowResult as ReturnType<typeof redirectTo>
          if (redirectResponse.redirect) {
            const PAGES_USE_WINDOW_LOCATION: string[] = [
              configuration.path.login
            ]
            if (
              PAGES_USE_WINDOW_LOCATION.some((url) =>
                redirectResponse.redirect.destination.startsWith(url)
              )
            ) {
              window.location.href = redirectResponse.redirect.destination
            } else {
              router.push(redirectResponse.redirect.destination)
            }
          } else {
            setSuspend(false)
          }
          router.beforePopState(({ url, as, options }) => {
            // I only want to allow these two routes!
            const flowResult = handleAuthenticationFlow({
              user: getUserAuthentication(cookies),
              resolvedUrl: as,
              query: router.query,
              redirectPath: configuration.path.login,
              currentTenant: cookies[SESSION_COOKIE_CURRENT_TENANT],
              extendedReturn: {},
              planInfo
            })
            if (redirectResponse.redirect) {
              const redirectResponse = flowResult as ReturnType<
                typeof redirectTo
              >
              window.location.href = redirectResponse.redirect.destination
              return false
            }
            return true
          })
        } else {
          setSuspend(false)
        }
      }
    }, [router, data, isMatch])

    return suspend && isMatch ? (
      <></>
    ) : (
      <Component {...props} showOnServer={!isMatch} />
    )
  }
  return WithClientAuthenticationMiddleware
}
export default withClientAuthenticationMiddleware

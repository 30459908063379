import { useRouter } from 'next/router'
import { parseCookies } from 'nookies'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import useFcmToken, {
  FIREBASE_REQUEST_EXPLAIN,
  IFirebaseToken
} from 'src/firebase/hooks/useFcmToken'
import configuration from '~/configuration'
import { mappingCurrentTenantCookie } from '~/cookies/currentTenant'
import { FCC, IRole, ITenantDetail } from '~/core/@types/global'
import {
  SESSION_COOKIE_CURRENT_TENANT,
  SESSION_COOKIE_CURRENT_TENANT_EXT,
  SESSION_EXPIRES_AT_COOKIE_NAME
} from '~/core/constants/cookies'
import {
  setSessionCookieClient,
  setSessionCookieExtClient
} from '~/core/middleware/save-session-cookie'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import QueryUserPermissionsList from '~/lib/features/permissions/graphql/query-user-permissions-list'
import QueryCareersSetting from '~/lib/features/settings/careers/graphql/query-careers-setting'
import { CareerSettingType } from '~/lib/features/settings/careers/types'
import QueryShowTenantMember from '~/lib/features/settings/members/graphql/query-show-member'
import useWorkSpace from '~/lib/features/settings/workspace/hooks/use-workspace'
import QueryTenantFeatureSetting from '~/lib/graphql/query-tenant-feature-setting'
import useBoundStore from '~/lib/store'
import { ITenantPermissionSettingType } from '~/lib/store/permission-setting-slice'
import { refetchSubscriptionData } from '../Subscription/useSubscriptionPlan'

export const PAGES_NOT_FETCH_API: string[] = [
  configuration.path.authError,
  configuration.path.careers.list,
  configuration.path.contactUs,
  configuration.path.error404,
  configuration.path.error500,
  configuration.path.errorAccessDenied,
  configuration.path.extension.list,
  configuration.path.login,
  configuration.path.termsOfUse,
  configuration.path.register,
  configuration.path.onboarding,
  configuration.path.verifyEmail,
  configuration.path.tenantBlocked,
  configuration.path.privacyPolicy,
  configuration.path.selectCompany,
  configuration.path.errorExpiredPlan,
  configuration.path.settings.plans_expired
]

const PermissionWrapper: FCC<{ children: ReactElement }> = ({ children }) => {
  const router = useRouter()
  const { clientGraphQL } = useContextGraphQL()
  const {
    setPermissionSetting,
    setToast,
    user,
    currentRole,
    setCurrentRole,
    setDeviceRegistrations,
    setFeatureSetting,
    setUser
  } = useBoundStore()
  const { subscribeFCM } = useFcmToken()
  const cookies = parseCookies()
  const [isFirstLoaded, setFirstLoaded] = useState(true)
  const { tenantShow: dataCompany, fetchWorkspace } = useWorkSpace({
    shouldPause: true
  })

  useEffect(() => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      if (user?.id) {
        subscribeFCM((payload: { data: { title: string } }) => {
          if (
            payload.data.title ===
            FIREBASE_REQUEST_EXPLAIN.userPermissionsListChanged.title
          ) {
            fetchPermissions()
          }

          if (
            payload.data.title ===
            FIREBASE_REQUEST_EXPLAIN.PlanSubscriptionChanged.title
          ) {
            refetchSubscriptionData(clientGraphQL)
          }

          if (
            payload.data.title ===
            FIREBASE_REQUEST_EXPLAIN.tenantReferralSettingChanged.title
          ) {
            fetchTenantFeatureSetting()
          }

          if (
            payload.data.title ===
            FIREBASE_REQUEST_EXPLAIN.tenantRequisitionSettingChanged.title
          ) {
            fetchTenantFeatureSetting()
          }

          if (
            payload.data.title ===
            FIREBASE_REQUEST_EXPLAIN.tenantCareerSiteSettingChanged.title
          ) {
            fetchTenantSettingCareerSite()
          }
        })
      }

      return () => {
        subscribeFCM()
      }
    }

    return
  }, [user])

  useEffect(() => {
    if (
      !PAGES_NOT_FETCH_API.some((url) => router?.pathname.startsWith(url)) &&
      user?.id
    ) {
      fetchPermissions()
      fetchTenantFeatureSetting()

      if (isFirstLoaded === true) {
        setFirstLoaded(false)
        fetchWorkspace()
      }

      if (currentRole === undefined) {
        fetchInformationUsers()
      }
    }
  }, [user])

  useEffect(() => {
    if (dataCompany && Object.keys(dataCompany).length > 0) {
      if (user.currentTenant) {
        const obj = {
          ...user.currentTenant,
          ...dataCompany,
          careerSiteSettings: dataCompany.careerSiteSettings
        }
        const currentTenantObject = mappingCurrentTenantCookie(obj)

        setSessionCookieClient(
          SESSION_COOKIE_CURRENT_TENANT,
          JSON.stringify(currentTenantObject).toString(),
          Number(cookies[SESSION_EXPIRES_AT_COOKIE_NAME])
        )
        setSessionCookieExtClient(
          SESSION_COOKIE_CURRENT_TENANT_EXT,
          JSON.stringify(currentTenantObject).toString(),
          Number(cookies[SESSION_EXPIRES_AT_COOKIE_NAME])
        )

        setUser({
          ...user,
          currentTenant: currentTenantObject as unknown as ITenantDetail
        })
      }
    }
  }, [dataCompany])

  const fetchPermissions = useCallback(() => {
    return clientGraphQL
      .query(QueryUserPermissionsList, { userId: Number(user.id) })
      .then(
        (result: {
          error: { graphQLErrors: Array<object> }
          data: {
            userPermissionsList: {
              collection: ITenantPermissionSettingType[]
            }
          }
        }) => {
          if (result.error) {
            return catchErrorFromGraphQL({
              error: result.error,
              setToast
            })
          }

          const { userPermissionsList } = result.data || {}
          return setPermissionSetting(userPermissionsList?.collection || [])
        }
      )
  }, [clientGraphQL, user])

  const fetchTenantFeatureSetting = useCallback(() => {
    return clientGraphQL.query(QueryTenantFeatureSetting).then(
      (result: {
        error: { graphQLErrors: Array<object> }
        data: {
          tenantFeatureSetting: {
            key: string
            enabling_feature: boolean
          }[]
        }
      }) => {
        if (result.error) {
          return catchErrorFromGraphQL({
            error: result.error,
            setToast
          })
        }

        const { tenantFeatureSetting } = result.data || {}
        return setFeatureSetting(tenantFeatureSetting)
      }
    )
  }, [clientGraphQL, user])

  const fetchInformationUsers = useCallback(() => {
    clientGraphQL.query(QueryShowTenantMember, { id: Number(user.id) }).then(
      (result: {
        error: { graphQLErrors: Array<object> }
        data: {
          showTenantMember: {
            deviceRegistrations: IFirebaseToken[]
            roles: IRole[]
          }
        }
      }) => {
        if (result.error) {
          return catchErrorFromGraphQL({
            error: result.error,
            setToast
          })
        }

        const { showTenantMember } = result.data || {}
        setCurrentRole(showTenantMember?.roles?.[0])
        setDeviceRegistrations(showTenantMember?.deviceRegistrations || [])

        return
      }
    )
  }, [clientGraphQL, user])

  const fetchTenantSettingCareerSite = useCallback(async () => {
    return clientGraphQL
      .query(QueryCareersSetting, {})
      .toPromise()
      .then(
        (result: {
          error: { graphQLErrors: object[] }
          data: { tenantSettingsCareerSite: CareerSettingType }
        }) => {
          if (result.error) {
            return catchErrorFromGraphQL({
              error: result.error,
              setToast
            })
          }

          const { tenantSettingsCareerSite } = result.data
          if (user?.currentTenant) {
            const obj = {
              ...user.currentTenant,
              careerSiteSettings: tenantSettingsCareerSite
            }
            const currentTenantObject = mappingCurrentTenantCookie(obj)

            setSessionCookieClient(
              SESSION_COOKIE_CURRENT_TENANT,
              JSON.stringify(currentTenantObject).toString(),
              Number(cookies[SESSION_EXPIRES_AT_COOKIE_NAME])
            )
            setSessionCookieExtClient(
              SESSION_COOKIE_CURRENT_TENANT_EXT,
              JSON.stringify(currentTenantObject).toString(),
              Number(cookies[SESSION_EXPIRES_AT_COOKIE_NAME])
            )

            setUser({
              ...user,
              currentTenant: {
                ...user?.currentTenant,
                careerSiteSettings: {
                  ...user?.currentTenant?.careerSiteSettings,
                  enablingCareerSiteSetting:
                    tenantSettingsCareerSite?.enablingCareerSiteSetting
                }
              } as ITenantDetail
            })
          }

          return
        }
      )
  }, [clientGraphQL, user])

  return children
}

export default PermissionWrapper

import { parseCookies } from 'nookies'
import { useMemo } from 'react'
import { createClient, fetchExchange } from 'urql'
import configuration from '~/configuration'
import {
  SESSION_COOKIE_CURRENT_TENANT,
  SESSION_COOKIE_IP,
  SESSION_COOKIE_NAME
} from '../constants/cookies'
import { AGENCY_TENANT } from '../constants/enum'
import { DEFAULT_LOCALE } from '../constants/env'

/**
 * @name useGraphQLRequest
 * @description A hook to make queries language
 */
export function useGraphQLRequest<Resp = unknown, Body = void>({
  language
}: {
  language?: string
}) {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  const cookies = parseCookies()
  const authenticationToken = cookies[SESSION_COOKIE_NAME]
  const ip = cookies[SESSION_COOKIE_IP]
  const currentTenantCookie = cookies[SESSION_COOKIE_CURRENT_TENANT]
  const currentTenantObj = currentTenantCookie
    ? JSON.parse(currentTenantCookie)
    : {}

  const client = useMemo(
    () =>
      createClient({
        url:
          currentTenantObj?.companyKind === AGENCY_TENANT
            ? configuration.api.agencyGraphQL
            : configuration.api.graphQL,
        exchanges: [fetchExchange],
        fetchOptions: () => {
          return {
            headers: {
              authorization: `Bearer ${authenticationToken}`,
              language: language || DEFAULT_LOCALE,
              timezone: timeZone,
              IP: String(ip)
              // 'Content-Type': 'application/json'
            }
          }
        }
      }),
    [currentTenantObj?.companyKind, authenticationToken, language, ip]
  )

  return client
}

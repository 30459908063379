import { StateCreator } from 'zustand'
import { LayoutSlice } from './layout-slice'
import { ToastSlice } from './toast-slice'

export interface ITenantPermissionSettingType {
  visibility: boolean
  moduleName: string
  kind: string
  toggleable: boolean
  enabled: boolean
  objects: Array<{
    name: string
    enabled: boolean
    actions: Array<string>
    permissions: Array<string>
  }>
}

export interface TenantPermissionSettingSlice {
  permissionSetting: ITenantPermissionSettingType[]
  setPermissionSetting: (
    permissionSetting: ITenantPermissionSettingType[]
  ) => void
}

export const createTenantPermissionSettingSlice: StateCreator<
  LayoutSlice & ToastSlice & TenantPermissionSettingSlice,
  [],
  [],
  TenantPermissionSettingSlice
> = (set: Function) => ({
  permissionSetting: [],
  setPermissionSetting: (permissionSetting: ITenantPermissionSettingType[]) =>
    set({ permissionSetting })
})
